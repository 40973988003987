<template>
  <div
    class="flix-container"
    v-if="membership && membership.membership === 'free'"
  >
    <div class="flix-well">
      <div
        v-html="
          $t('message.accountFreeWarning', { total: count[2], count: count[1] })
            .split('\n')
            .join('<br>')
        "
      />
      <div class="flex flex-column flex-center flex-gap-5" style="justify-content: flex-start;">
        <a
          class="flix-btn flix-btn-xs flix-btn-success"
          :href="'https://bookingflix.' + ($i18n.locale === 'en'? 'net': $i18n.locale) + '/prices'"
          target="_blank"
          >{{ $tc('message.price', 2) }}</a
        >
        <br /><br />
        <router-link
          :to="{ name: 'dashboardPremium' }"
          class="flix-btn flix-btn-xs flix-btn-success"
          >{{
            $t('message.goTo', { name: $t('message.membership') })
          }}</router-link
        >
      </div>
    </div>
  </div>
</template>
<script>
export default {
  components: {},
  props: {},
  data() {
    return {
      membership: false,
      count: false,
      variables: this.$getUserVariables()
    }
  },
  computed: {},
  mounted() {
    this.getMembership()
    this.getBookingCount()
  },
  methods: {
    getBookingCount() {
      this.$flix_post({
        url: 'booking/get_count',
        data: {
          user: this.variables.user.md5_id
        },
        callback: function (ret) {
          this.count = ret.data
        }.bind(this)
      })
    },
    getMembership() {
      this.$flix_post({
        url: 'user/get_membership',
        data: {
          user: this.variables.user.md5_id
        },
        callback: function (ret) {
          this.membership = ret.data[1]
        }.bind(this)
      })
    }
  }
}
</script>
<style lang="sass" scoped></style>
